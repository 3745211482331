import React from "react";
import { AiOutlineHtml5 } from "react-icons/ai";
import { SiCsswizardry } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { SiExpress } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { IoLogoNodejs } from "react-icons/io";
import { SiReact } from "react-icons/si";
import { SiRedux } from "react-icons/si";
import { SiPostman } from "react-icons/si";
import { SiFigma } from "react-icons/si";
import { FaAppStoreIos } from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";
import { SiTailwindcss } from "react-icons/si";
import "./skills.css";

const Skills = () => {
  return (
    <section id="skills">
      <h2>Skills</h2>
      <div className="main_icon_div">
        <div className="icon_div">
          <p className="icon">{<SiReact />}</p>
          <p className="icon">React</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<SiReact />}</p>
          <p className="icon">React Native</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<SiRedux />}</p>
          <p className="icon">Redux</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<AiOutlineHtml5 />}</p>
          <p className="icon">HTML</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<SiCsswizardry />}</p>
          <p className="icon">CSS</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<SiJavascript />}</p>
          <p className="icon">Javascript</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<FaAppStoreIos />}</p>
          <p className="icon">IOS</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<IoLogoAndroid />}</p>
          <p className="icon">Android</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<SiTailwindcss />}</p>
          <p className="icon">TailwindCSS</p>
        </div>
        <div className="icon_div">
          <p className="icon">{<SiFigma />}</p>
          <p className="icon">Figma</p>
        </div>
      </div>
    </section>
  );
};

export default Skills;
