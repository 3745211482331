import React from 'react'
import './footer.css'
import {FaWhatsapp} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>Aman Raj</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#portfolio">Project</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className='footer_socials'>
        <a href="https://www.linkedin.com/in/aman-raj-59b8971a7/" target="_blank"><FaLinkedinIn/></a>
        <a href="https://wa.me/+919471087119" target="_blank"><FaWhatsapp/></a>
      </div>

      <div className='footer_copyright'>
        <small>&copy;Aman Raj. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer