import React, { useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

Modal.setAppElement("#root");

function Post() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { id } = useParams();
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Example Modal"
      >
        <h2>Open Mysynr</h2>
        <p style={{ color: "#000" }}>Open the page in mysynr app</p>
        <p style={{ color: "#000" }}>
          <a
            style={{
              width: "200px",
              height: "60px",
              background: "#4db5ff",
              padding: "10px 20px",
              borderRadius: "10px",
              color: "white",
              cursor: "pointer",
              marginTop: "20px",
            }}
            href={`mysynr://post/${id}`}
          >
            Open App
          </a>
        </p>
      </Modal>
    </div>
  );
}

export default Post;
