import React, { useState } from 'react'
import './nav.css'
// import {SiAboutdotme} from 'react-icons/si'
// import {AiOutlineHome} from 'react-icons/ai'
// import {MdCastForEducation} from 'react-icons/md'
// import {AiOutlineProject} from 'react-icons/ai'
// import {MdContacts} from 'react-icons/md'

const Nav = () => {
  const [active,setActive] = useState('#')
  return (
    <nav>
      <a href="#" onClick={() => setActive('#')} className={active === '#'? 'active':''}>Home</a>
      <a href="#about" onClick={() => setActive('#about')} className={active === 'about'? 'active':''} >About</a>
      <a href="#skills" onClick={() => setActive('#skills')} className={active === 'skills'? 'active':''}>Skills</a>
      <a href="#portfolio" onClick={() => setActive('#portfolio')} className={active === 'portfolio'? 'active':''}>Projects</a>
      <a href="#contact" onClick={() => setActive('#contact')} className={active === 'contact'? 'active':''}>Contact</a>
    </nav>
  )
}

export default Nav