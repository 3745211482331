import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaMedium} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
     <a href="https://www.linkedin.com/in/aman-raj-59b8971a7/" target="_blank"><BsLinkedin/></a>
     <a href="https://github.com/aman-638" target="_blank"><FaGithub/></a>
     <a href="https://medium.com/@ar7009297" target="_blank"><FaMedium/></a>
    </div>
  )
}

export default HeaderSocials