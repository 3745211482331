import React from "react";
import "./portfolio.css";
import Mysynr from "../../assets/mysynrwelcome.png";
import mecarreira from "../../assets/mecarreira.png";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h2>Projects</h2>
      <div className="project">
        <div className="main_div">
          <div className="img_div">
            <img src={mecarreira} alt="mecarreira landing page" />
          </div>
          <div className="name_detail">
            <h2>Mecarreira</h2>
            <h5>
              MeCarreira is a DeFi project enabling football players to launch
              their own DAO, meCarreira website provides a user-friendly GUI for
              interacting with the meCarreira Protocol, but participation is
              possible solely through direct smart contract interactions.
            </h5>
            <h3>Tech Stack</h3>
            <h5>
              Reactjs &nbsp;|&nbsp; Redux &nbsp;|&nbsp; Web3js &nbsp;|&nbsp;
              Wert &nbsp;|&nbsp; Django &nbsp;|&nbsp; Sql DB
            </h5>
          </div>
          <div className="button">
            <a
              href="https://mecarreira.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live Demo
            </a>
            {/* <a href="https://github.com/aman-638/store.rockstartgames.com-Frontend-Clone" target="_blank" className='btn'>Github</a> */}
          </div>
        </div>

        <div className="main_div">
          <div className="img_div">
            <img src={Mysynr} alt="mysynr landing page" />
          </div>
          <div className="name_detail">
            <h2>Mysynr.com</h2>
            <h5>
              User create a video on your favorite audio file & Rock your own
              video and Use Lip Syncing, Props, Body magic to create magic while
              recording video.
            </h5>
            <h3>Tech Stack</h3>
            <h5>
              React Native &nbsp;|&nbsp; Reactjs &nbsp;|&nbsp; IOS &nbsp;|&nbsp;
              Android &nbsp;|&nbsp; Redux &nbsp;|&nbsp; TailwindCSS
              &nbsp;|&nbsp; NodeJs &nbsp;|&nbsp; Mongo DB &nbsp;|&nbsp; AWS
              Reactjs
            </h5>
          </div>
          <div className="button">
            <a
              href="https://mysynr.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live Demo
            </a>
            {/* <a href="https://github.com/aman-638/tata-Mg1-clone" target="_blank" className='btn'>Github</a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
