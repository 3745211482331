import React from "react";
import CTA from "./CTA";
import svg from "../../assets/fds1.svg";
import "./header.css";

const Header = () => {
  return (
    <header>
      <div className="main_header">
        <div className="name_detail">
          <h4>Hello I'm</h4>
          <h1>Aman Raj</h1>
          <h4 className="text-light">Frontend Developer</h4>
          <CTA />
        </div>

        <div className="img">
          <img src={svg} alt="svg image" />
        </div>
      </div>
    </header>
  );
};

export default Header;
